<template>
	<div>

		<!-- 
			Backend Errors 
		-->
		<div v-if="isError === true">
			<div v-for="error in errors" :key="error">
				<div v-for="el in error" :key="el">
					<ion-item>
						<div tabindex="0"></div><!-- bug fix for safari 14 -->
						<ion-text color="danger">{{el}}</ion-text>
					</ion-item>
				</div>
			</div>
		</div>
		<!-- 
			Image 
		-->
		<ion-item v-if="imageUrl != null" class="padding-top-md">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-img :src="imageUrl" />
		</ion-item>
		<!-- 
			ID
		-->
		<ion-item class="padding-top-md">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			ID: {{id}}
		</ion-item>
		<!-- 
			Name 
		-->
		<ion-item v-if="sceneType != 'state'">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="name" type="text" placeholder="Name"></ion-input>
		</ion-item>		
		<!-- 
			Slug
		-->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="slug" type="text" placeholder="Hashtag"></ion-input>
		</ion-item>	
		<!-- 
			Region 
		-->
		<ion-item v-if="sceneType == 'metro'">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-label position="stacked">Region</ion-label>
			<ion-select placeholder="Select One" v-model="region">
				<ion-select-option v-for="item in regions" :key="item" :value="item.slug">
					{{item.name}}
				</ion-select-option>
			</ion-select>
		</ion-item>
		<!-- 
			Zip Code 
		-->
		<ion-item v-if="sceneType == 'metro'">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="zipCode" type="text" placeholder="Zip code"></ion-input>
		</ion-item>
		<!-- 
			Status 
		-->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-label position="stacked">Status</ion-label>
			<ion-select placeholder="Select One" v-model="status">
				<ion-select-option :value="1">Published</ion-select-option>
				<ion-select-option :value="0">Unpublished</ion-select-option>
				<ion-select-option :value="2">Delete</ion-select-option>
			</ion-select>
		</ion-item>
		<!-- 
			Actions 
		-->
		<ion-item lines="none">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-button v-if="imageUrl === null" slot="start" expand="full" size="small" color="light" @click="takePicture()">
				<ion-icon :icon="image"></ion-icon>
			</ion-button>
			<ion-button v-if="imageUrl != null" slot="start" expand="full" size="small" color="danger" @click="imageUrl = null">
				<ion-icon :icon="trash"></ion-icon>
			</ion-button>
			<ion-button v-if="isValidated === true" slot="end" expand="full" size="small" color="primary" @click.prevent="updateScene()">
				Save
			</ion-button>
		</ion-item>
	</div>
</template>

<script>
import { IonItem, IonInput, IonLabel, IonSelect, IonSelectOption, IonImg, IonButton, IonIcon, IonText, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import { image, trash } from 'ionicons/icons';
import apiClient from '../services/api';
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'SceneEditForm',
	props: ['id'],
	components: {
		IonItem, IonInput, IonLabel, IonSelect, IonSelectOption, IonImg, IonButton, IonIcon, IonText
	},
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const scene = ref([]);
		const sceneType = ref(null);
		const name = ref(null);
		const slug = ref(null);
		const region = ref(null);
		const state = ref(null);
		const country = ref(null);
		const zipCode = ref(null);
		const regions = ref([]);
		const status = ref(1);
		const imageUrl = ref(null);
		const isValidated = ref(false);
		const isError = ref(false);
		const errors = ref([]);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }

		onMounted(() => {
			getScenesByType('region')
		})

		function getScene() {
			apiClient.get('/api/get/scene/'+props.id,
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				scene.value = response.data
			}).catch(error => {
				console.log(error)
			}); 			
		}

		function getScenesByType(val) {
			apiClient.get('/api/get/scenes/type/' + val, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				console.log(response)
				if(val == 'region') {
					regions.value = response.data
				}
			}).catch(error => {
				console.log(error)  
			});      
		}

		async function takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					imageUrl.value = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}

		function validateForm() {
			// SceneType has been selected
			if(sceneType.value != null) {
				// Metro
				if(sceneType.value == 'metro') {
					// Name
					if(name.value != null && name.value != '') {
						// Slug
						if(slug.value != null && slug.value != '') {
							// State
							if(state.value != null) {
								// zipCode
								if(zipCode.value != null && zipCode.value != '') {
									isValidated.value = true
								} 
								// zipCode has not been entered
								else {
									isValidated.value = false
								}							
							} 
							// State has not been entered
							else {
								isValidated.value = false
							}							
						} 
						// Slug has not been entered
						else {
							isValidated.value = false
						}
					} 
					// Name has not been entered
					else {
						isValidated.value = false
					}
				}
				// State
				if(sceneType.value == 'state' || sceneType.value == 'region' || sceneType.value == 'country') {
					if(name.value != null && name.value != '') {
						// Slug
						if(slug.value != null && slug.value != '') {
							isValidated.value = true
						} else {
							isValidated.value = false
						}
					} else {
						isValidated.value = false
					}
				}
			} 
			// SceneType has not been selected
			else {
				isValidated.value = false
			}
		}

		function updateScene() {

			presentLoading()
			apiClient.post('/api/update/scene/'+props.id, 
			{
				scene_type: sceneType.value,
				name: name.value,  
				region: region.value, 
				zip_code: zipCode.value,
				image: imageUrl.value,
				status: status.value,
				slug: slug.value,
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have updated a scene.'
					openToast()
					isError.value = false
					errors.value = []
					goBack()
				}
				// Success
				if(response.data.message == 'success-delete') {
					toastMessage.value = 'You have deleted a scene.'
					openToast()
					isError.value = false
					errors.value = []
					goBack()
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
					openToast()
				}
				// Fail Auth
				if(response.data.message == 'fail-slug') {
					toastMessage.value = 'That hashtag is already taken.'
					openToast()
				}
			})
			.catch(error => {
				// credentials didn't match
				isError.value = true
				errors.value = error.response.data.errors
			});        	
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function goBack() {
			router.push('/admin-scenes')
		}

		return {
			authUser, scene, getScene, sceneType, name, slug, region, regions, status, imageUrl, image, trash, takePicture, zipCode, validateForm, state, country, isValidated, isError, errors, updateScene, router
		}
	},
	watch: {
		id: function() {
			if(this.id > 0) {
				this.getScene()
			}
		},
		scene: {
			handler() {
				if(this.scene) {
					this.sceneType = this.scene.type
					this.name = this.scene.name
					this.slug = this.scene.slug
					this.region = this.scene.region
					this.state = this.scene.state
					this.country = this.scene.country
					this.zipCode = this.scene.zip_code
					this.status = this.scene.status
					this.imageUrl = this.scene.image
				}	
			},
			deep:true
		},
		name: function() {
			this.validateForm()
		},
		slug: function() {
			this.validateForm()
		},
		state: function() {
			this.validateForm()
		},
		region: function() {
			this.validateForm()
		},
		country: function() {
			this.validateForm()
		},
		zipCode: function() {
			this.validateForm()
		},
		abbreviation: function() {
			this.validateForm()
		}		
	}
});
</script>